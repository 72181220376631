import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/Loader/Loader';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { ResultSerivces } from 'Services/resultService';
import { parse } from 'json2csv';
import { CSVLink } from 'react-csv';
interface IMarksModal {
    show: any,
    onHide: any,
    examId: any
}

const MarksModal = ({ show, onHide, examId }: IMarksModal) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [marking, setMarking] = useState<any>({
        marksForCorrectOption: "",
        // marksForIncorrectOption: "0",
        marksWhenIsDeletedTrue: "",
        marksForIncorrectOption: ""
    });

    // const [csvData, setCsvData] = useState<any>();
    // const [chemistryCsv, setChemistryCsv] = useState<any>();
    // const [mathCsv, setMathCsv] = useState<any>();
    // const [physicsCsv, setPhysicsCsv] = useState<any>();


    const onDownload = (data: any) => {

        fetch(data).then(response => {
            response.blob().then(blob => {

            })
        })
    }


    const handleMarking = (e: any) => {
        setMarking({ ...marking, [e.target.name]: e.target.value });
    }


    // const generateResult = async (e: any) => {
    //     setLoading(true);
    //     await toast.promise(
    //         async () => await ResultSerivces.generateResult(examId, marking).then(async (res) => {
    //             if (res.status === 200) {
    //                 // console.log(res.data);
    //                 // const csv = parse(res.data);
    //                 // console.log(csv);
    //                 // const blob = new Blob([csv], { type: 'text/csv' });
    //                 // const fileURL = URL.createObjectURL(blob);
    //                 // let alink = document.createElement('a');
    //                 // alink.href = fileURL;
    //                 // alink.download = 'results.csv';
    //                 // alink.click();
    //                 // onHide();
    //                 // onDownload(csv);

    //                 const csv = res.data?.globalResults?.map((data: any) => {
    //                     return {
    //                         centerId: data?.centerId,
    //                         center_name: data?.center_name,
    //                         city: data?.city,
    //                         correctQuestions: data?.correctQuestions,
    //                         email: data?.email,
    //                         incorrectQuestions: data?.incorrectQuestions,
    //                         last_name: data?.last_name,
    //                         marksForCorrectOption: data?.marksForCorrectOption,
    //                         marksForDeletedQuestion: data?.marksForDeletedQuestion,
    //                         marksForIncorrectOption: data?.marksForIncorrectOption,
    //                         name: data?.name,
    //                         registrationNumber: data?.registrationNumber,
    //                         percentile: data?.percentile,
    //                         Chemistry_sectionTotalMarks: data?.sectionTotalMarks?.Chemistry,
    //                         Mathematics_sectionTotalMarks: data?.sectionTotalMarks?.Mathematics,
    //                         Physics_sectionTotalMarks: data?.sectionTotalMarks?.Physics,
    //                         setChemistryCsv: data?.sectionWiseResults?.Chemistry,
    //                         setMathCsv: data?.sectionWiseResults?.Mathematics,
    //                         setPhysicsCsv: data?.sectionWiseResults?.Physics,
    //                     }
    //                 })

    //                 setCsvData(res.data?.globalResults?.map((data: any) => {
    //                     return {
    //                         centerId: data?.centerId,
    //                         center_name: data?.center_name,
    //                         city: data?.city,
    //                         correctQuestions: data?.correctQuestions,
    //                         email: data?.email,
    //                         incorrectQuestions: data?.incorrectQuestions,
    //                         last_name: data?.last_name,
    //                         marksForCorrectOption: data?.marksForCorrectOption,
    //                         marksForDeletedQuestion: data?.marksForDeletedQuestion,
    //                         marksForIncorrectOption: data?.marksForIncorrectOption,
    //                         name: data?.name,
    //                         registrationNumber: data?.registrationNumber,
    //                         percentile: data?.percentile,
    //                         Chemistry_sectionTotalMarks: data?.sectionTotalMarks?.Chemistry,
    //                         Mathematics_sectionTotalMarks: data?.sectionTotalMarks?.Mathematics,
    //                         Physics_sectionTotalMarks: data?.sectionTotalMarks?.Physics,
    //                     }
    //                 }))
    //             }
    //         }).catch(err => {
    //             toast.error("Unable to generate results")
    //         }), {
    //         pending: "Generating Results",
    //         success: "Results Generated",
    //         error: "Unable to generate results"
    //     })
    //     setLoading(false);
    // }

    // const generateResult = async () => {
    //     setLoading(true);

    //     await toast.promise(
    //         async () => {
    //             const res = await ResultSerivces.generateResult(examId, marking);

    //             if (res.status === 200) {
    //                 const globalResults = res.data?.globalResults || [];

    //                 // Global Results
    //                 const globalCsv = globalResults.map((data: any) => ({
    //                     centerId: data?.centerId,
    //                     center_name: data?.center_name,
    //                     city: data?.city,
    //                     correctQuestions: data?.correctQuestions,
    //                     email: data?.email,
    //                     incorrectQuestions: data?.incorrectQuestions,
    //                     last_name: data?.last_name,
    //                     marksForCorrectOption: data?.marksForCorrectOption,
    //                     marksForDeletedQuestion: data?.marksForDeletedQuestion,
    //                     marksForIncorrectOption: data?.marksForIncorrectOption,
    //                     name: data?.name,
    //                     registrationNumber: data?.registrationNumber,
    //                     percentile: data?.percentile,
    //                     Chemistry_sectionTotalMarks: data?.sectionTotalMarks?.Chemistry,
    //                     Mathematics_sectionTotalMarks: data?.sectionTotalMarks?.Mathematics,
    //                     Physics_sectionTotalMarks: data?.sectionTotalMarks?.Physics,
    //                 }));

    //                 setCsvData(globalCsv);

    //                 // Subject-wise Results
    //                 const chemistryCsv = globalResults.map((data: any) => ({
    //                     registrationNumber: data?.registrationNumber,
    //                     name: data?.name,
    //                     Chemistry_sectionTotalMarks: data?.sectionTotalMarks?.Chemistry,
    //                     marks: data?.sectionWiseResults?.Chemistry[0]?.marks,
    //                     questionId: data?.sectionWiseResults?.Chemistry[0]?.questionId,
    //                     status: data?.sectionWiseResults?.Chemistry[0]?.status,
    //                     subject: data?.sectionWiseResults?.Chemistry[0]?.subject,
    //                     subjectCode: data?.sectionWiseResults?.Chemistry[0]?.subjectCode,
    //                 }));

    //                 const mathCsv = globalResults.map((data: any) => ({
    //                     registrationNumber: data?.registrationNumber,
    //                     name: data?.name,
    //                     Mathematics_sectionTotalMarks: data?.sectionTotalMarks?.Mathematics,
    //                     marks: data?.sectionWiseResults?.Chemistry[0]?.marks,
    //                     questionId: data?.sectionWiseResults?.Mathematics[0]?.questionId,
    //                     status: data?.sectionWiseResults?.Mathematics[0]?.status,
    //                     subject: data?.sectionWiseResults?.Mathematics[0]?.subject,
    //                     subjectCode: data?.sectionWiseResults?.Mathematics[0]?.subjectCode,
    //                 }));


    //                 const physicsCsv = globalResults.map((data: any) => ({
    //                     registrationNumber: data?.registrationNumber,
    //                     name: data?.name,
    //                     Physics_sectionTotalMarks: data?.sectionTotalMarks?.Physics,
    //                     marks: data?.sectionWiseResults?.Physics[0]?.marks,
    //                     questionId: data?.sectionWiseResults?.Physics[0]?.questionId,
    //                     status: data?.sectionWiseResults?.Physics[0]?.status,
    //                     subject: data?.sectionWiseResults?.Physics[0]?.subject,
    //                     subjectCode: data?.sectionWiseResults?.Physics[0]?.subjectCode,

    //                 }));

    //                 setChemistryCsv(chemistryCsv);
    //                 setMathCsv(mathCsv);
    //                 setPhysicsCsv(physicsCsv);

    //                 toast.success("Results Generated");
    //             } else {
    //                 toast.error("Unable to generate results");
    //             }
    //         },
    //         {
    //             pending: "Generating Results",
    //             success: "Results Generated",
    //             error: "Unable to generate results"
    //         }
    //     );

    //     setLoading(false);
    // };

    const generateResult = async () => {
        setLoading(true);
    
        await toast.promise(
            async () => {
                const res = await ResultSerivces.generateResult(examId, marking);
    
                if (res.status === 200) {
                    const chemistry = res.data?.chemistry || [];
                    const global = res.data?.global || [];
                    const mathematics = res.data?.mathematics || [];
                    const physics = res.data?.physics || [];
    
                    const downloadCsv = (csvData: any[], fileName: string) => {
                        const blob = new Blob([parse(csvData)], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(blob);
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
    
                    // Generate CSVs for each section
                    downloadCsv(chemistry, 'Chemistry_Results.csv');
                    downloadCsv(mathematics, 'Mathematics_Results.csv');
                    downloadCsv(physics, 'Physics_Results.csv');
                    downloadCsv(global, 'Global_Results.csv');
    
                    toast.success('Section-wise Results Generated');
                } else {
                    toast.error('Unable to generate results');
                }
            },
            {
                pending: 'Generating Section-wise Results',
                success: 'Results Generated Successfully',
                error: 'Error Generating Results',
            }
        );
    
        setLoading(false);
    };
    



    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Generate Result
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Marks for correct Option</Form.Label>
                            <Form.Control type="number" name="marksForCorrectOption" onChange={handleMarking} required />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Negative Marks</Form.Label>
                            <Form.Control type="number" name="marksForIncorrectOption" defaultValue={0} onChange={handleMarking} readOnly />
                        </Form.Group> */}
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Marks for Incorrect Option</Form.Label>
                            <Form.Control type="number" name="marksForIncorrectOption" onChange={handleMarking} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Marks for deleted Question</Form.Label>
                            <Form.Control type="number" name="marksWhenIsDeletedTrue" onChange={handleMarking} required />
                        </Form.Group>
                        <div className="d-flex align-item-center">
                            <Button variant="primary" type="button" className="d-flex float-right me-3" onClick={generateResult} disabled={loading}>
                                <FontAwesomeIcon icon={faGears} className="me-1" />  Generate
                            </Button>
                            <Button variant="danger" type="button" className="ms-2" onClick={onHide}>
                                Cancel
                            </Button>
                        </div>
                        {/* <div className="mt-3 d-flex justify-content-between flex-wrap gap-3">
                            {csvData?.length > 0 && (
                                <div>
                                    <CSVLink data={csvData} className = "btn btn-primary" filename="global_results.csv">
                                        Download Global Results
                                    </CSVLink>
                                    <CSVLink data={chemistryCsv} className = "btn btn-primary" filename="chemistry_results.csv">
                                        Download Chemistry Results
                                    </CSVLink>
                                    <CSVLink data={mathCsv} className = "btn btn-primary" filename="math_results.csv">
                                        Download Math Results
                                    </CSVLink>
                                    <CSVLink data={physicsCsv} className = "btn btn-primary" filename="physics_results.csv">
                                        Download Physics Results
                                    </CSVLink>
                                </div>
                            )}
                        </div> */}
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MarksModal